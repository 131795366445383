import React from 'react';
import './styles.scss'
import HistoryData from 'models/HistoryData'


export default class History extends React.Component {

    state = {
        history: [],
        selectedHistory: undefined
    };

    async componentDidMount() {

        this.setState({history: await HistoryData.getAll()})
        const link = document.querySelector(".tablinks");
        if(link) {
            link.click();
        }
    }

    render() {
        return (<div id="story">
            <div className="title-box">
                <h2>{this.props.headline}</h2>
            </div>

            <div className="Tabmodule">

                <div className="tab">
                    {this.state.history.map(item =>
                        <button key={item.title} className="tablinks" onClick={this.openTab.bind(this, item.title.replace(/\s/g, ''))}>{item.title}</button>
                    )}
                </div>

                <div className="tab-container">
                    {this.state.history.map(item =>
                        <div key={item.title.replace(/\s/g, '')} id={item.title.replace(/\s/g, '')} className="tabcontent">
                            <div>
                                <img src={item.image} alt={item.title}/>
                            </div>
                            <div>
                                <h4>{item.title}</h4>
                                <span className="date">{item.date}</span>
                                <p>
                                    {item.description}
                                </p>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>);
    }

    openTab(cityName, evt) {
        // Declare all variables
        var i, tabcontent, tablinks;

        // Get all elements with class="tabcontent" and hide them
        tabcontent = document.getElementsByClassName("tabcontent");
        for (i = 0; i < tabcontent.length; i++) {
            tabcontent[i].style.display = "none";
        }

        // Get all elements with class="tablinks" and remove the class "active"
        tablinks = document.getElementsByClassName("tablinks");
        for (i = 0; i < tablinks.length; i++) {
            tablinks[i].className = tablinks[i].className.replace(" active", "");
        }

        // Show the current tab, and add an "active" class to the button that opened the tab
        document.getElementById(cityName).style.display = "flex";
        evt.currentTarget.className += " active";
    }
}
