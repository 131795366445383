import React from 'react';
import './styles.scss';

export default class ToTop extends React.Component {

	componentDidMount() {
		window.addEventListener('scroll', this.handleScroll);
	}

	componentWillUnmount() {
		window.removeEventListener('scroll', this.handleScroll);
	}

	handleScroll(event) {
		let supportPageOffset = window.pageXOffset !== undefined;
		let isCSS1Compat = ((document.compatMode || '') === 'CSS1Compat');
		let scroll = {
			x: supportPageOffset ? window.pageXOffset : isCSS1Compat ? document.documentElement.scrollLeft : document.body.scrollLeft,
			y: supportPageOffset ? window.pageYOffset : isCSS1Compat ? document.documentElement.scrollTop : document.body.scrollTop
		};
		let element = document.getElementById("toTop")
		if (scroll.y > 900) {
			element.classList.remove('hidden');
		}
		if (scroll.y < 600) {
			element.classList.add('hidden');
		}
	}

	render () {
		return (<div id="toTop" className="hidden">
			<link href="//netdna.bootstrapcdn.com/font-awesome/3.2.1/css/font-awesome.css" rel="stylesheet" />
			<a href="#top" id="return-to-top"><i className="icon-chevron-up"></i></a>
		</div>);
	}
}
