import axios from 'axios'

export default class Locations {
	constructor(data) {
		if(data) {
			Object.keys(data).forEach(key => this[key] = data[key])
		}
	}

	static async getAll() {
		const { data } = await axios.get(`${process.env.REACT_APP_BE_URL}/api/v1/locations`)
		return data
	}
}