import React from 'react';
import './styles.scss'

import Faq from 'models/Faq'

export default class Accordeon extends React.Component {

    state = {
        accordeons: [],
        selectedItem: undefined
    };


    async componentDidMount() {
        this.setState({accordeons: await Faq.getAll()})
    }

    open(item) {
        this.setState({
            selectedItem: item
        })
    }

    render() {
        const {selectedItem} = this.state;

        return (
            <div id={this.props.id}>
                <h2>{this.props.headline}</h2>
                <section className="accordion-container">
                    {this.props.children}
                    {this.state.accordeons.map(item => (
                        <React.Fragment key={item.id}>
                            <button className="accordion" key={item.id}
                                    onClick={() => this.open(item)}>{item.question}</button>
                            {selectedItem && item.id === selectedItem.id && (
                                <div className="panel" key={item.id}>
                                    <p>{item.answer}</p>
                                </div>)
                            }
                        </React.Fragment>
                    ))}
                </section>
            </div>
        )
    }
}
