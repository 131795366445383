import React from 'react';
import './styles.scss'

export default class Hero extends React.Component {
    render() {
        const style = {
            backgroundImage: `url('${this.props.image}')`
        }

        return (
            <div className="hero" style={style}>
                {this.props.children}
            </div>
        )
    }
}