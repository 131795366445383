import React from 'react'
import './styles.scss'
import Animate from '../Animate'
import PersonData from 'models/Persons.js'

export default class Mention extends React.Component {

    state = {
        persons: [],
    };

    async componentDidMount () {
        this.setState({persons: await PersonData.getAll()})
    }

    render() {
        const {persons} = this.state;
        const bride = persons[1]
        const groom = persons[0]
        if (bride === undefined || groom === undefined) {
            return <div className='loader'>...</div>;
        }
        return (
            <div id="couple-container">
                <div id="couple">
                    <div>
                        <h2>Glückliches Paar</h2>
                    </div>
                    <Animate animation="slide-from-left">
                        <div className="groom">
                            <div className="picture">
                                <img src={ groom.image }
                                     alt={ 'Foto von ' + groom.name }/>
                            </div>

                            <div className="details">
                                <h3>Der Bräutigam</h3>
                                <h4>{ groom.name }</h4>
                                <div className="table-responsive">
                                    <table className="table" border="0" cellSpacing="0">
                                        <tbody>
                                        <tr>
                                            <th>Name</th>
                                            <td>{ groom.name }</td>
                                        </tr>
                                        <tr>
                                            <th>Beruf</th>
                                            <td>{ groom.job }</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <p>{ groom.description }</p>
                            </div>
                        </div>
                    </Animate>
                    <Animate animation="slide-from-right">
                        <div className="bride">
                            <div className="details">
                                <h3>Die Braut</h3>
                                <h4>{ bride.name }</h4>
                                <div className="table-responsive">
                                    <table className="table" border="0" cellSpacing="0">
                                        <tbody>
                                        <tr>
                                            <th>Name</th>
                                            <td>{ bride.name }</td>
                                        </tr>
                                        <tr>
                                            <th>Beruf</th>
                                            <td>{ bride.job }</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <p>{ bride.description }</p>
                            </div>

                            <div className="picture">
                                <img src={ bride.image }
                                     alt={ 'Foto von ' + bride.name }/>
                            </div>
                        </div>
                    </Animate>
                </div>
            </div>
        )
    }
}
