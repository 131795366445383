import React from 'react';
import './styles.scss'
import Animate from '../Animate'

const second = 1000;
const minute = second * 60;
const hour = minute * 60;
const day = hour * 24;

export default class Counter extends React.Component {
    state = {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0
    }

    componentDidMount() {
        this.counter = setInterval(this.update, 500)
    }

    update = () => {
        let diff = new Date(this.props.date).getTime() - new Date().getTime() + new Date().getTimezoneOffset() * minute;

        if (diff >= 0) {
            const days = Math.floor(diff / day);
            diff = diff % day;
            const hours = Math.floor(diff / hour);
            diff = diff % hour;
            const minutes = Math.floor(diff / minute);
            diff = diff % minute;
            const seconds = Math.floor(diff / second);

            this.setState({
                days,
                hours,
                minutes,
                seconds
            });
        } else {
            this.setState({
                days: 0,
                hours: 0,
                minutes: 0,
                seconds: 0
            });
        }
    };

    componentWillUnmount() {
        clearInterval(this.counter);
    }

    render() {
        const {days, hours, minutes, seconds} = this.state;
        return (
            <div id="counter">
                <div id="counter-inner">
                    <div className="headline">
                        <h3>{this.props.headline}</h3>
                    </div>
                    <div className="digits">
                        <Animate animation="slide-from-top" sequential>
                            <div className="digit">
                                <span>{days.toString().padStart(2, '0')}</span>
                                <span>Tag{days !== 1 && 'e'}</span>
                            </div>
                            <div className="digit">
                                <span>{hours.toString().padStart(2, '0')}</span>
                                <span>Stunde{hours !== 1 && 'n'}</span>
                            </div>
                            <div className="digit">
                                <span>{minutes.toString().padStart(2, '0')}</span>
                                <span>Minute{minutes !== 1 && 'n'}</span>
                            </div>
                            <div className="digit">
                                <span>{seconds.toString().padStart(2, '0')}</span>
                                <span>Sekunde{seconds !== 1 && 'n'}</span>
                            </div>
                        </Animate>
                    </div>
                </div>
            </div>
        )
    }
}
