import React from 'react';
import './styles.scss'

export default class Divider extends React.Component {
    state = {
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    render() {
        return (
            <div className="divider"><span></span><span>{this.props.children}</span><span></span></div>
        )
    }
}



