import React from 'react';
import Banner from '../Banner'
import './styles.scss';
import Slides from 'models/Greetings'

export default class Slider extends React.Component {

	constructor (props) {
		super(props);
		this.state = {
			index: 0,
			slides: []
		};
	}


	async componentDidMount () {
		this.timer = setInterval(this.slide, this.props.delay || 2000)
		this.setState({slides: await Slides.getAll()})
	}

	slide = () => {
		this.setState({
			index: this.nextIndex()
		})
	}

	nextIndex = () => this.state.index + 1 < this.state.slides.length ? this.state.index + 1 : 0

	prevIndex = () => this.state.index - 1 < 0 ? this.state.slides.length - 1 : this.state.index - 1

	componentWillUnmount () {
		clearInterval(this.timer)
	}

	render () {

		var slideCount = [], i = 0, len = this.state.slides.length
		while (++i <= len) slideCount.push(i)
		let currentItem = this.state.slides[this.state.index]
		return (
			<section className="slider-container">
				<ul>
					<li className={"slide slide--" + (this.state.index + 1)}>
						<Banner>
							{currentItem && (
								<div>
									<span className="headline"></span>
									<p className="text">{currentItem.text}</p>
									<h4 className="subline">{currentItem.author}</h4>
								</div>
							)}
						</Banner>
					</li>
				</ul>
				<div className="index-counter">
					<ol>
						{slideCount.map(i => {
							return <li key={i} className={this.state.index === i-1 ? 'active' : ''}>{i}</li>
						})}
					</ol>
				</div>
			</section>
		);
	}
}
