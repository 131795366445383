import React from 'react'
import './styles.scss'
import Animate from "components/Animate";
import GalleryImage from 'models/GalleryImage'


export default class Gallery extends React.Component {

	state = {
		images: [],
		selectedImage: undefined
	};

	async componentDidMount () {
		this.setState({images: await GalleryImage.getAll()})
	}

	selectImage(image) {
		document.documentElement.style.overflow = image ? 'hidden' : 'auto';
		this.setState({
			selectedImage: image
		})
	}

	render () {
		const {images, selectedImage} = this.state;
		return (<div id="gallery">
			<div className="gallery">
				<div className="title-box">
					<h2>{this.props.headline}</h2>
				</div>
				<div className="images">
					<Animate sequential>
						{images.map(item =>
							<img className="gallery-image" key={item.url} src={item.thumb}
							     onClick={() => this.selectImage(item)}
							     alt={item.title}/>)}
					</Animate>
					{selectedImage && (
						<div id="overlay" style={{ backgroundImage: `url('${selectedImage.url}')`}} onClick={() => this.selectImage()}>
							<div className="text-block"><h4>{selectedImage.title}</h4><p>{selectedImage.description}</p></div>
							}
						</div>)}
				</div>
			</div>
		</div>)
	}


}
