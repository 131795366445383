import React from 'react';
import '../Approach/styles.scss';
import Animate from '../Animate'

import Locations from 'models/Locations'

export default class Approach extends React.Component {

    state = {
        locations: [],
        selectedLocation: undefined
    };

    async componentDidMount() {
        const locations = await Locations.getAll();
        this.setState({locations, selectedLocation: locations.length ? locations[0] : undefined})
    }

    selectItem = item => {
        this.setState({
            selectedLocation: item
        });
    }

    render() {
        const { selectedLocation, locations } = this.state;

        return (
            <section id="events">
                <div className="container">
                    <div className="title-box">
                        <h2>{this.props.headline}</h2>
                    </div>
                    <div className="content">
                        <Animate animation="slide-from-left">
                            <div className="event-boxes" >
                                {this.props.children}

                                {locations.map((item) =>
                                    <div key={item.title} className={`main-ceromony ${item.id === selectedLocation.id ? 'active' : ''}`} onClick={() => this.selectItem(item)}>
                                        <h4>{item.title}</h4>
                                        <ul>
                                            <li><i className="icon-calendar"></i> <span dangerouslySetInnerHTML={{__html: item.date}} /></li>
                                            <li><i className="icon-location-arrow"></i> {item.location}</li>
                                        </ul>
                                    </div>
                                )}
                            </div>
                        </Animate>
                        <Animate animation="slide-from-right">
                            {locations.map(item =>
                                <div key={item.title} className={"mapframe map_"+item.id+" " + ( item.id === selectedLocation.id ? '' : "hidden")}>
                                    <iframe title="map_1"
                                            src={item.iFrame}
                                            width="620" height="425" frameBorder="0" allowFullScreen></iframe>
                                </div>
                            )}
                        </Animate>
                    </div>
                </div>
            </section>
        );
    }
}
