import React from 'react';
import './styles.scss';
import Registration from 'models/Registration';

export default class Register extends React.Component {

    state = {
        registration: new Registration(),
        counter: 0,
        message: null,
        error: null
    }

    changeForm = (key, value) => this.setState({
        registration: {
            ...this.state.registration,
            [key]: value
        }
    })

    register = async () => {
        const response = await Registration.save(this.state.registration)
        if (!response.data.error) {
            this.setState({
                registration: new Registration(),
                message: 'Danke für deine Anmeldung, Deine Registrierung war erfolgreich.',
                error: 'Danke für deine Anmeldung, Deine Registrierung war erfolgreich.'
            })
        } else {
            this.setState({
                error: response.data.error
            })
        }
    }

    async componentDidMount() {
        this.setState({counter: await Registration.getAll()})
    }

    render() {

        const {registration, counter, message, error} = this.state;
        return (
            <section id="register">
                <div className="container">
                    <div className="container-outer">
                        <div className="container-inner">
                            <h4>Bitte bestätige deine Teilnahme<br/><u>vor</u> dem 31.12.2019</h4>
                            <section className="batch">
                                <div className="batch-box">
                                    Bisher<br/>bestätigt
                                    <div className="batch-counter"><strong>{counter}</strong></div>
                                </div>
                            </section>
                            {error &&
                            <h3 className="message">{error}</h3>
                            }
                            {!message &&
                            <div id="register-form" className="form form-inline">
                                <div className="row">
                                    <div>
                                        <input value={registration.name}
                                               onChange={ev => this.changeForm(ev.target.name, ev.target.value)}
                                               type="text" className="form-control" name="name" placeholder="Dein Name"
                                               required="1"/>
                                    </div>
                                    <div>
                                        <input value={registration.email}
                                               onChange={ev => this.changeForm(ev.target.name, ev.target.value)}
                                               type="email" className="form-control" name="email"
                                               placeholder="Deine Email-Adresse"
                                               required="1"/>
                                    </div>
                                    <div>
                                        <select onChange={ev => this.changeForm(ev.target.name, ev.target.value)}
                                                name="guests" className="form-control" defaultValue="" required="1">
                                            <option value="" disabled="disabled">Anzahl der Gäste (Erwachsen +16
                                                Jahre)
                                            </option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                            <option value="6">6</option>
                                        </select>
                                    </div>
                                    <div>
                                        <select onChange={ev => this.changeForm(ev.target.name, ev.target.value)}
                                                name="guestsChild" className="form-control" defaultValue=""
                                                required="1">
                                            <option value="" disabled="disabled">Anzahl der Gäste (Kinder)</option>
                                            <option value="0">0</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                            <option value="6">6</option>
                                        </select>
                                    </div>
                                    <div>
                                        <select onChange={ev => this.changeForm(ev.target.name, ev.target.value)}
                                                name="events" className="form-control" defaultValue="" required="1">
                                            <option value="" disabled="disabled">An welchen Events nimmst du Teil?
                                            </option>
                                            <option value="1">Bei allen Events</option>
                                            <option value="2">Hochzeitszeremonie</option>
                                            <option value="3">Hochzeitsfeier</option>
                                        </select>
                                    </div>

                                    <div className="form-group col col-sm-12">
                                            <textarea value={registration.notes}
                                                      onChange={ev => this.changeForm(ev.target.name, ev.target.value)}
                                                      name="notes" className="form-control" wrap="off" cols="30"
                                                      rows="5"
                                                      placeholder="Deine Nachricht..."/>
                                    </div>

                                    <div className="form-group col col-sm-12">
                                        <button type="button" className="btn" onClick={() => this.register()}>Einladung
                                            Bestätigen
                                        </button>
                                        <span id="loader"><img src="./heart-preloader.svg" alt="Loader"/></span>
                                    </div>
                                    <div className="success"> Danke dir!</div>
                                    <div className="error"> Es ist ein Fehler aufgetreten. Bitte versuche es zu einem
                                        Späteren Zeitpunkt noch einmal.
                                    </div>
                                </div>
                            </div>
                            }
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
