import React from 'react';
import './styles.scss'
import Gifts from 'models/Gifts'
import Divider from '../Divider'

export default class Gift extends React.Component {

    state = {
        gifts: [],
    };

    async componentDidMount() {
        this.setState({gifts: await Gifts.getAll()})
    }

    render() {
        return (
            <section className="gift-registry">
                <div className="container">
                    <div className="title-box">
                        <h2>{this.props.headline}</h2>
                    </div>
                    <br />
                    <h4>Materielles Glück</h4>
                    <div className="cta">
                        {this.props.children}
                    </div>
                    <div className="text">
                        <p>{this.props.text}</p>
                    </div>
                    <Divider>Oder</Divider>

                    <h4>Hochzeitsreise-Budget</h4>
                    <div className="flex-parent">
                        <div className="input-flex-container">
                            {this.state.gifts.map(item => (
                                <React.Fragment key={item.id}>
                                    <div className={"input fadein " + item.active}>
                                        <span data-year={item.amount} data-info={item.name}></span>
                                    </div>
                                </React.Fragment>
                            ))}
                        </div>
                    </div>
                    <div className="text">
                    <p>
                        {this.props.textCash}
                    </p>
                    </div>
                </div>
            </section>
        );
    }
}
