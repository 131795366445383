import React from 'react';
import './styles.scss';

export default class Banner extends React.Component {
    render() {
        return (
            <div className="banner">
                {this.props.children}
            </div>
        );
    }
}
