import React from 'react';
import './styles.scss';

export default class Animate extends React.Component {
    constructor(props) {
        super(props)

        this.container = React.createRef()

        this.state = {
            onScreen: false
        }
    }

    componentDidMount() {
        this.handleScroll()
        window.addEventListener('scroll', this.handleScroll, {
            passive: true
        })
    }

    handleScroll = () => {
        if(this.container && this.container.current) {
            const positionStart = this.container.current.offsetTop;
            const positionEnd = positionStart + this.container.current.clientHeight;
            const scrollTop = document.documentElement.scrollTop;
            this.setState({
                onScreen: positionEnd > scrollTop && positionStart < document.documentElement.clientHeight + scrollTop
            })
        }
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll)
    }

    render() {
        const {onScreen} = this.state;
        const style = {};
        if(this.props.delay)
            style.animationDelay = this.props.delay;
        const className = `${this.props.sequential ? 'sequential-' : ''}${this.props.animation || 'fadein'}`;
        return <div ref={this.container} style={style} className={onScreen ? className : ''}>{this.props.children}</div>
    }
}
