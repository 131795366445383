import axios from 'axios'

export default class Registration {
	constructor(data) {
		if(data) {
			Object.keys(data).forEach(key => this[key] = data[key])
		}

	}

	name = ''
	email = ''
	notes = ''
	guests = "1"
	guestsChild = "0"
	events = "1"

	static save = async registration => await axios.post(`${process.env.REACT_APP_BE_URL}/api/v1/form`, registration)

	static async getAll() {
		// https://jsonplaceholder.typicode.com/
		const { data } = await axios.get(`${process.env.REACT_APP_BE_URL}/api/v1/register`)
		return data
	}
}