import {useEffect, useState} from 'react'

export const useGetAll = (modelClass, dependencies) => {
    const [list, setList] = useState([])

    useEffect(() => {
        modelClass.getAll().then(setList)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modelClass, ...(dependencies || [])])

    return list;
}