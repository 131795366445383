import React, {useState, useEffect} from 'react'
import './styles.scss'

import Navigation from "models/Navigation"
import {useGetAll} from "../../models/useModel";

const useHash = () => {
    const [hash, setHash] = useState(window.top.location.hash);

    useEffect(() => {
        const hashChangeHandler = () => {
            setHash(window.top.location.hash)
        }
        window.addEventListener("hashchange", hashChangeHandler);

        return () => window.removeEventListener('hashchange', hashChangeHandler);
    }, [])

    return hash
}

const Header = ({headline}) => {
    const nav = useGetAll(Navigation)
    const [active, setActive] = useState(false)
    const hash = useHash()

    const mobileClick = () => setActive(!active)

    const iterateNavigation = () => {
        return nav.map(function (item) {
            let active = (hash === item.route ? 'active' : '');
            return <li key={item.route} className={active} onClick={() => setActive(false)}>
                <a href={item.route} className={item.class}>{item.content}</a>
                {item.children && item.children.length > 0 &&
                <div className="subnavigation-container"><ul className="subnavigation">
                    {item.children.map(function (item) {
                        let active = (hash === item.route ? 'active' : '');
                        return <li key={item.route} className={active}><a
                            href={item.route}>{item.content}</a></li>
                    })}
                </ul></div>
                }
            </li>
        });
    }

    return (
        <div id="header">
            <div id="mobile" role="button" className={active ? 'active' : ''} onClick={mobileClick}>
                <div></div>
                <div></div>
                <div></div>
            </div>
            <div id="header-inner">
                <div id="logo">
                    {nav.length > 0 &&
                    <a href={nav[0].route}>
                        {headline}
                    </a>
                    }
                </div>
                <nav id="navigation-container" className={active ? 'active' : ''}>
                    <ul id="navigation">
                        {iterateNavigation()}
                    </ul>
                </nav>
            </div>
        </div>
    );

}

export default Header;