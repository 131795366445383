import React from 'react';
import './styles.scss'

export default class Paralax extends React.Component {
    render() {
        const style = {
            background: `url('${this.props.image}') center center/cover no-repeat fixed`
        }

        return (
            <section id={this.props.id} className="bquotes" style={style}>
                <h2>{this.props.headline}</h2>
                <div className="paralax-container">
                    {this.props.children}
                </div>
            </section>
            );
    }
}